import { useState } from 'react';
import logo from '../images/logo-lng.png';
// import Development from '../components/Development';
import WalkingSvg from '../images/undraw_modern_life_re_8pdp.svg';
import BikingSvg from '../images/undraw_biking_kc-4-f.svg';
import CarpoolSvg from '../images/undraw_electric_car_b-7-hl.svg';

const Home = () => {
  const [isAccordionOpened, setIsAccordionOpened] = useState('closed');
  const openAccordion = () => {
    console.log('hello!');
    isAccordionOpened === 'open'
      ? setIsAccordionOpened('closed')
      : setIsAccordionOpened('open');
  };
  return (
    <>
      {/* <Development /> */}
      <hr className='longLine' />
      <LandingPage />
      <div className='container'>
        <section className='section1' data-aos='fade-right'>
          <div className='section_icon'>
            <i className='fa-solid fa-exclamation'></i>
          </div>
          <h2>WHAT</h2>
          <h3>What's the Problem?</h3>
          <p>
            Transportation has a big problem when it comes to the environment.
            It has many different problems ranging from cars to whole building
            infrastructure. For example, building infrastructure has a big
            problem as it creates noise and damages the homes of many animals
            while it is being built. Also, transportation releases a lot of
            greenhouse gasses mainly C02 and creates other byproducts. People
            have tried to create other alternatives to stop burning fuel that
            creates most of this problem, but such fuel is usually expensive and
            currently can't be made using a sustainable method. Also, cars and
            other vehicles can have an impact on the environment. For example,
            airplanes can have something called a bird strike when the plane
            strikes a bird during takeoff or climb. Due to this, these birds die
            and the aircraft get damaged. Overall, transportation creates
            massive problems from its infrastructure to creating greenhouse
            gasses.
            <br />
            (Part of information comes from a moved-url webpage. Citation not
            available.)
          </p>
        </section>
        <section className='didyouknow'>
          <h2>
            <span className='orange'>
              Did you know,
              <br />
              2,5%
            </span>{' '}
            of <span className='orange'>C02</span> emission come from planes?
          </h2>
        </section>
        <section className='section2' data-aos='fade-right'>
          <div className='section_icon'>
            <i className='fa-solid fa-question'></i>
          </div>
          <h2>WHY</h2>
          <h3>Why take Action?</h3>
          <p>
            After reading an article about why transportation damages the
            ecosystem many people may ask the question "Why should we help?" I
            can answer the question right now for you! People should take action
            as with the increasing population more people will use
            transportation and cause more problems. These problems include
            people hitting animals, increasing pollution, and more
            infrastructure being built. Then more animals die as said in the
            section before. Due to all of these reasons, taking action would be
            important for people to minimize the impact of transportation on the
            environment. Hopefully, when a large enough group of people take
            action, more companies have to try to create more sustainable and
            efficient sources of fuel and propulsion. Then after all of this
            action will be taken, hopefully, the problem of transportation would
            be gone or minimized.
          </p>
        </section>
        <section data-spacer></section>
        <section
          className='section3 grid'
          data-aos='fade-right'
          id='--section&how_grid'
        >
          <div className='section_icon'>
            <i className='fa-solid fa-plus'></i>
          </div>
          <h2>HOW</h2>
          <h3>How to help?</h3>
          <div className='grid' id='grid-layout'>
            <div className='card'>
              <h4>WALKING</h4>
              <p>
                A great alternative to driving a car is walking as you can
                usually drive a car within a walking distance. Walking does not
                produce any byproducts as you walk with manpower.
              </p>
              <img
                src={WalkingSvg}
                alt=''
                className='card_img card_img--big-card'
              />
            </div>
            <div className='card'>
              <h4>BIKING</h4>
              <p>
                One alternative is biking. Biking is great to get farther places
                faster and more efficiently. Also, bikes also don't take a lot
                of resources to manufacture compared to cars.
              </p>
              <img src={BikingSvg} alt='' className='card_img' />
            </div>
            <div className='card'>
              <h4>CARPOOLING</h4>
              <p>
                Carpooling is a great alternative as you use only one car but
                the footprint for each person in the car is drastically
                decreased unlike you driving by yourself.
              </p>
              <img src={CarpoolSvg} alt='' className='card_img' />
            </div>
          </div>
        </section>
      </div>
      <footer>
        <h2>TRANSPORTATION4U @2023</h2>
        <br />
        <h3>WORKS CITED:</h3>
        <h2>QUICK ACCESS:</h2>
        <br />
        <img
          src={require('../images/exported_qrcode_image_600.png')}
          alt='https://docs.google.com/document/d/1YDYb9vYlrXKyo6UJrlLnLTKDkZVsgl2BLti_-2MCk2I/edit?usp=sharing'
          height={120}
          className='center'
        />
        <br />
        <button className='accordion_header' onClick={openAccordion}>
          SEE SOURCES
        </button>
        <div className={isAccordionOpened}>
          <pre>
            "Biking." <i>unDraw</i>, undraw.co/. Accessed 18 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            Chung, Emily. "5 Ways to Make Air Travel Greener." <i>CBC News</i>,
            CBC/Radio-Canada, 14 Aug. 2019,{' '}
          </pre>
          <pre>
            {' '}
            www.cbc.ca/news/science/climate-change-aviation-emissions-1.5244130.
            Accessed 12 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            "Electric Car." <i>unDraw</i>, undraw.co/. Accessed 18 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            McGarth, Jane. "Top 10 Alternative Transportation Methods."{' '}
            <i>HowStuffWorks</i>, science.howstuffworks.com/{' '}
          </pre>
          <pre>
            {' '}
            environmental/green-science/10-alternative-transportation-methods.htm.
            Accessed 14 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            "Modern Life." <i>unDraw</i>, undraw.co/. Accessed 18 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            "The Pros and Cons on Alternative Fuels." <i>Consumer Reports</i>,
            Feb. 2014, www.consumerreports.org/cro/{' '}
          </pre>
          <pre>
            {' '}
            2011/05/pros-and-cons-a-reality-check-on-alternative-fuels/{' '}
          </pre>
          <pre>
            {' '}
            index.htm#:~:text=Hydrogen%20fuel%20cells,space%2Dage%20technology%20is%20expensive.
            Accessed{' '}
          </pre>
          <pre> 12 Apr. 2023. </pre>{' '}
          <pre>
            Radomska, Marharyta, et al. "The Analysis of Airports' Physical
            Factors Impacts on Wildlife."{' '}
          </pre>
          <pre>
            {' '}
            <i>ResearchGate</i>, Apr. 2021, www.researchgate.net/publication/{' '}
          </pre>
          <pre>
            {' '}
            352216088_The_analysis_of_airports%27_physical_factors_impacts_on_wildlife.
            Accessed 12 Apr.{' '}
          </pre>
          <pre> 2023. </pre>{' '}
          <pre>
            Ritchie, Hannah. "Climate Change and Flying: What Share of Global
            CO2 Emissions Come from Aviation?"{' '}
          </pre>
          <pre>
            {' '}
            <i>Our World in Data</i>, Global Change Data Lab, 22 Oct. 2020,
            ourworldindata.org/{' '}
          </pre>
          <pre>
            {' '}
            co2-emissions-from-aviation#:~:text=It%20accounts%20for%20around%202.5,impacts%20on%20climate%20into%{' '}
          </pre>
          <pre>
            {' '}
            20account.&amp;text=October%2022%2C%202020-,Flying%20is%20a%20highly%20controversial%20topic%20in%20clima{' '}
          </pre>
          <pre>
            {' '}
            te%20debates.,personal%20and%20collective%20carbon%20emissions.
            Accessed 14 Apr. 2023.{' '}
          </pre>{' '}
          <pre>
            Spiller, Joanna. "Jaki Wpływ na Środowisko ma Europejski Sektor
            Transportu Morskiego?" ["What{' '}
          </pre>
          <pre>
            {' '}
            Impact on the Ecosystem does the European Section of Oceanic
            Transport have?"]. <i>Teraz </i>
          </pre>
          <pre>
            {' '}
            <i>Środowisko</i>, 10 Sept. 2021,
            www.teraz-srodowisko.pl/aktualnosci/{' '}
          </pre>
          <pre>
            {' '}
            raport-eea-transport-morski-10816.html. Accessed 12 Apr. 2023.{' '}
          </pre>
        </div>
      </footer>
    </>
  );
};

function LandingPage() {
  return (
    <div className='landingPage'>
      <h1>
        <img src={logo} alt='TRANSPORTATION 4 U' />
        Learn. Understand. Perform.
      </h1>
      <p>
        Learn about all the problems of transportation and have a positive
        impact on the environment.
      </p>
      <a href='#--section&how_grid' className='pill button'>
        PERFORM NOW
      </a>
      {/* <img className='bottomImg' src={passangersWaitingImg} alt='' /> */}
    </div>
  );
}
export default Home;
